import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { APP_PROTECTED_URL } from "../constants/config";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getUserRoles } from "../auth";
import roleToRoutes from './roleToRoutes'

export function RouteWithSubRoutes(route, { exact }) {
  return (
    <Route
      path={route.path}
      exact={exact}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} exact={exact} routes={route.routes} />
      )}
    />
  );
}

export function subRoutes(routes) {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} exact={true} {...route} />
      ))}
    </Switch>
  );
}

export function privateRoutes() {
  return [].map((r) => APP_PROTECTED_URL + r);
}

export function privateRoutesWithRoles(roles) {
  // if (roles.length === 0) return [];
  if (roles.length === 0) roles = ["business"];
  const roleBaseRoutes = roleToRoutes
    .filter((r) => roles.includes(Object.keys(r)[0]))
    .reduce((acc, cur) => acc.concat(cur[Object.keys(cur)[0]]), [])
    .map((r) => APP_PROTECTED_URL + r);

  return roleBaseRoutes;
}

export function authRoutes(user) {
  return [...privateRoutes(), ...privateRoutesWithRoles(getUserRoles(user))];
}

export function getProtectedLazyPage(importPath) {
  const comp = lazy(() => import(`../pages/${importPath}`));
  return withAuthenticationRequired(comp);
}
