import React from "react";
import { BrowserRouter as RouteProvider } from "react-router-dom";
import { ThemeProvider, styled } from "@material-ui/core";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import GlobalStyles from "./components/GlobalStyles";
import Theme from "./theme";
import Router from "./routing/Router";
import { Helmet } from "react-helmet";

import { SITE_NAME } from "./constants/config";

const StyledRouter = styled("div")(({ theme }) => ({
  height: "100%",
  fontFamily: theme.typography.fontFamily,
  fontWeightRegular: theme.typography.fontWeightRegular,
}));

export default function App() {
  return (
    <RouteProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SITE_NAME}</title>
        <link rel="canonical" href="http://{SITE_NAME.toLowerCase()}.com/" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@100&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Auth0ProviderWithHistory>
        <ThemeProvider theme={Theme}>
          <GlobalStyles />
          <StyledRouter>
            <Router />
          </StyledRouter>
        </ThemeProvider>
      </Auth0ProviderWithHistory>
    </RouteProvider>
  );
}
