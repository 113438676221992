import axios from 'axios'
import { serialize } from '../utils' 

const getParams = () => {
  return {
    db: 'eabatements-api-dev',
    collection: 'DocumentIntake',
    city: process.env.REACT_APP_CITY
  }
} 

const metaData = [
  // { id: 'submissionID', numeric: true, disablePadding: true, label: 'Submission ID' },
  { id: 'taxpayerName', numeric: false, disablePadding: true, label: 'Taxpayer Name' },
  { id: 'parcelId', numeric: false, disablePadding: true, label: 'Parcel ID' },
  { id: 'submissionDate', numeric: true, disablePadding: true, label: 'Submission Date' }
]

export const getCityDocuments = async (setIsLoading, setIsError) => {
  setIsLoading(true)
  setIsError(false)
  let cityDocs = []
  try {
    cityDocs = await axios.get(`${process.env.REACT_APP_MONGO_API_URL}?${serialize(getParams())}`)
  } catch (error) {
    setIsError(true)
    setIsLoading(false)

  }
  
  let documents = [];
  cityDocs.data.forEach((doc) => {
    documents.push({
      submissionID: doc.submissionID,
      formID: doc.formID,
      eventID: doc.eventID,
      taxpayerName: doc.document.taxpayerName,
      formTitle: doc.formTitle,
      parcelId: doc.document.parcelId,
      submissionDate: doc.createdDate.slice(0, 10),
    })
  })

  setIsLoading(false)

  return {documents, metaData}
}