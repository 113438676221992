import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Table from "../../../../components/Table"
import PDFViewer from "../../../../components/PDFViewer"
import {Paper, Grid, Box, styled } from '@material-ui/core';
import { getCityDocuments } from '../../../../api/cityAdmin'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  minHeight: "100%"
}));

const useDocumentPageStyles = makeStyles((theme) => ({
  mainContent: {
    height: '100%'
  },
}));

export default function DocumentsPage() {
  const classes = useDocumentPageStyles()
  const [documents, setDocuments] = React.useState([]);
  const [metaData, setTableMetaData] = React.useState([]);
  const [isPdfVisible, setPdfVisible] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  
  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getCityDocuments(setIsLoading, setIsError)
      setTableMetaData(result.metaData)
      setDocuments(result.documents)
    };

    fetchData();
  }, []);

  return (
  <>
    <h1>Documents Page</h1>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={ isPdfVisible ? 8 : 12 }>
          <Item>
            <Table 
              documents={documents}
              metaData={metaData}
              setPdfUrl={setPdfUrl}
              setPdfVisible={setPdfVisible}
              isError={isError}
              isLoading={isLoading}
            />
          </Item>
        </Grid>
        {isPdfVisible && (
          <Grid item xs={4}>
            <Item>
              <PDFViewer
                className={classes.mainContent} 
                pdfUrl={pdfUrl} 
              />
            </Item>
          </Grid>
        )}
      </Grid>
    </Box>
  </>
  )
}
