import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import MailIcon from "@material-ui/icons/Mail";
import FolderIcon from "@material-ui/icons/Folder";
import AssessmentIcon from "@material-ui/icons/Assessment";
import HomeIcon from "@material-ui/icons/Home";
import { colors } from "../constants/styles";

const AbatementsIcon = () => (
  <svg
    width="6.35mm"
    height="6.3517mm"
    version="1.1"
    viewBox="0 0 6.35 6.3517"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="matrix(.070886 0 0 .079279 -3.482 -4.2971)"
      strokeWidth="1.0006"
    >
      <g transform="translate(-964.95,-288.49)">
        <g fill={colors.dark} strokeWidth=".2835">
          <path
            transform="matrix(3.7325 0 0 3.3374 1014.1 342.69)"
            d="m4.5625 1.3789v4.5352h1.1035v1.9277s2.3705 0.50649 2.5098 3.3516h-1.7598s0.142-1.8763-1.8535-1.8535v3.4727c0.18478 0.058507 0.36419 0.1167 0.58203 0.17383 0 0 3.554 0.81813 3.2754 4.0918 0 0-0.16536 2.2706-2.7441 2.7383v1.8906l-1.1133-0.003906v2.3027h5.0059v-16.152c-1.9034-1.7274-3.6379-3.8779-5.0059-6.4746zm0 13.305v3.627c0.061334-0.002118 0.11716 9.34e-4 0.18164-0.003906 0 0 1.6366-0.057787 1.8457-1.4023 0.1026-1.3241-0.85327-1.8121-2.0273-2.2207z"
          />
          <path
            transform="matrix(3.7325 0 0 3.3374 1014.1 342.69)"
            d="m11.42 9.3672v14.639h5.0078v-11.834c-1.6633-0.6685-3.3699-1.5978-5.0078-2.8047z"
          />
          <path
            transform="matrix(3.7325 0 0 3.3374 1014.1 342.69)"
            d="m23.393 12.475-5.0059 1.3613v10.17h5.0059v-11.531z"
          />
        </g>
        <path
          d="m1082.1 389.01 19.309-4.6929-15.798-12.361-0.7588 3.6491s-26.599-1.6655-55.92-32.22c12.249 23.82 34.676 37.268 53.758 42.184z"
          // fill="#a6cdd2"
          fillOpacity="0"
          stroke={colors.dark}
          strokeLinecap="square"
          strokeWidth="2.0009"
        />
        <path
          d="m1045.5 399.69s-0.6176 7.5766-10.243 9.1374v6.3084l-8.682-0.0308v-6.1783s-10.471-1.756-10.991-11.381h6.4382s0.066 6.8937 9.7555 6.2433c0 0 6.1133-0.19519 6.8936-4.6825 0.961-11.089-22.803-4.626-22.502-20.421 0 0-0.1934-7.6091 10.341-9.9503l-0.1319-6.3084h8.8446v6.4384s8.8451 1.6909 9.3651 11.186h-6.5687s0.7258-8.3822-10.991-5.658c0 0-4.6226 0.93238-4.0906 5.7437 0.1187 1.0839 0.9042 3.6864 10.334 5.8976 0 0 13.267 2.7315 12.227 13.657"
          // fill="#a6cdd2"
          fillOpacity="0"
          stroke="#484848"
          strokeWidth="2.8667"
        />
      </g>
    </g>
  </svg>
);
const AnalysisIcon = () => <AssessmentIcon style={{ color: colors.dark }} />;
const FormSubmitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g fill={colors.dark}>
        <path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z M8,15.01 l1.41,1.41L11,14.84V19h2v-4.16l1.59,1.59L16,15.01L12.01,11L8,15.01z" />
      </g>
    </g>
  </svg>
);
const InboxIcon = () => <MailIcon style={{ color: colors.dark }} />;
const DocumentsIcon = () => <FolderIcon style={{ color: colors.dark }} />;
const ProfileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
  >
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
    </g>
    <g>
      <g>
        <path d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z" />
        <path d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z" />
        <path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" />
      </g>
    </g>
  </svg>
);

const MySettingsIcon = () => <SettingsIcon sx={{ color: colors.dark }} />;
const SupportIcon = () => <HelpIcon style={{ color: colors.dark }} />;
const HouseIcon = () => <HomeIcon style={{ color: colors.dark }} />;

export const LogoIcon = ({ background }) => (
  <img alt="e-abatements-icon" src={`/static/icon_${background}.svg`} />
);

export default function getIcon(icon) {
  const icons = {
    "/app/inbox": <InboxIcon />,
    "/app/documents": <DocumentsIcon />,
    "/app/my-documents": <DocumentsIcon />,
    "/app": <HouseIcon />,
    "/app/my-abatements": <AbatementsIcon />,
    "/app/abatements": <AbatementsIcon />,
    "/app/settings": <MySettingsIcon />,
    "/app/analysis": <AnalysisIcon />,
    "/app/form-submit": <FormSubmitIcon />,
    "/app/support": <SupportIcon />,
    "/app/profile": <ProfileIcon />,
    "ARCHIVE_/home": <HouseIcon />,
  };
  return icons[icon];
}
