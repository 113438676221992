import { createTheme } from "@material-ui/core/styles";
import { colors } from "./constants/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Roboto"].join(","),
    fontWeightLight: 1200,
    fontWeightRegular: 1200,
    fontWeightMedium: 1200,
  },
  palette: {
    primary: {
      dark: colors.darkBlue,
      main: colors.lightBlue,
      light: colors.white,
    },
    secondary: {
      dark: colors.darkGreen,
      main: colors.lightGreen,
      light: colors.offwhite,
    },
    error: {
      main: colors.red,
    },
  },
  sizing: {},
});

export default theme;
