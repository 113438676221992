import React from "react";
import { Link } from "react-router-dom";
import {
  styled,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Box,
} from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import LogoIcon from "./Logo";
import { useAuth0 } from "@auth0/auth0-react";

const NavbarLayoutWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingLeft: 224,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const StyledIcon = styled(LogoIcon)(() => ({
  maxWidth: 55,
}));

const LogoutIcon = styled(InputIcon)(() => ({
  color: "white",
}));

export default function DashboardNavbar({ toggleSidebar }) {
  const { logout } = useAuth0();

  return (
    <AppBar elevation={0}>
      <NavbarLayoutWrapper>
        <StyledToolbar>
          <Hidden mdUp>
            <Link to="/">
              <StyledIcon />
            </Link>
            <IconButton color="inherit" onClick={toggleSidebar}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            <LogoutIcon />
          </IconButton>
        </StyledToolbar>
      </NavbarLayoutWrapper>
    </AppBar>
  );
}
