import React, { useState } from "react";
import { styled } from "@material-ui/core";
import DashboardSidebar from "./DashboardSidebar";
import DashboardNavbar from "./DashboardNavbar";
import { subRoutes } from "../routing";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  display: "flex",
  flexDirection: "column",
  alignContent: "space-between",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  height: "100%",
  [theme.breakpoints.up("md")]: {
    paddingLeft: 224,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  height: "100%",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  minHeight: "calc(100vh - 64px - 60px);`",
  overflow: "hidden",
});

export default function DashboardLayout({ routes }) {
  const [sidebarOpen, toggleSidebar] = useState(false);

  const handleToggleSidebar = () => toggleSidebar(!sidebarOpen);
  const handleCloseSidebar = () => toggleSidebar(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar toggleSidebar={handleToggleSidebar} />
      <DashboardSidebar
        sidebarOpen={sidebarOpen}
        closeSidebar={handleCloseSidebar}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{subRoutes(routes)}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
      {/* <Footer /> */}
    </DashboardLayoutRoot>
  );
}
