import React, { useState } from "react";
import { styled } from "@material-ui/core";
import MainNavbar from "./MainNavbar";
import MainSidebar from "./MainSidebar";
import Footer from "./Footer";
import { subRoutes } from "../routing";

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignContent: "space-between",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  // [theme.breakpoints.up("md")]: {
  //   paddingLeft: 224,
  // },
}));

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  minHeight: "calc(100vh - 64px - 60px);`",
  overflow: "hidden",

});

export default function MainLayout({ routes }) {
  const [sidebarOpen, toggleSidebar] = useState(false);
  const handleToggleSidebar = () => toggleSidebar(!sidebarOpen);
  const handleCloseSidebar = () => toggleSidebar(false);

  return (
    <MainLayoutRoot>
      <MainNavbar toggleSidebar={handleToggleSidebar} />
      <MainSidebar
        sidebarOpen={sidebarOpen}
        closeSidebar={handleCloseSidebar}
      />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>{subRoutes(routes)}</MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
      <Footer />
    </MainLayoutRoot>
  );
}
