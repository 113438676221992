import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect({ screen_hint: "signup" })}
      className="btn btn-primary"
      style={{ marginTop: "1rem" }}
    >
      Signup
    </button>
  );
};

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      className="btn btn-primary"
      style={{ marginTop: "1rem" }}
    >
      Login
    </button>
  );
};

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() => logout({ returnTo: window.location.origin })}
      className="btn btn-primary"
      style={{ marginTop: "1rem" }}
    >
      Logout
    </button>
  );
};

export default function AuthButton(signup = false) {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <LogoutButton />;
  } else if (signup === true) {
    return <SignupButton />;
  } else {
    return <LoginButton />;
  }
}
