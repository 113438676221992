const roleToRoutes = [
    {
      "dev-admin": [
        "",
        "/user",
        "/taxpayer",
        "/application",
        "/sb1",
        "/cf1",
        "/property",
        "/abatement",
      ],
    },
    {
      "city-admin": [
        "",
        "/abatements",
        "/documents",
        "/form-submit",
        "/profile",
        // "/analysis",
        // "/inbox",
        // "/settings",
        // "/support",
      ],
    },
    {
      "user-admin": ["", "/my-users", "/admin-support"],
    },
    {
      "business": [
        "",
        "/profile",
        // "/form-submit",
        // "/my-documents",
        // "/my-abatements",
      ],
    },
  ];

export default roleToRoutes;