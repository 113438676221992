import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import MainLayout from "../components/MainLayout";
import DashboardLayout from "../components/DashboardLayout";
import { RouteWithSubRoutes, getProtectedLazyPage } from ".";

import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import FAQ from "../pages/FAQ";
import NotFound404 from "../pages/NotFound404";
import Documents from "../pages/private/city/city-admin/Documents";
import * as config from "../constants/config";

const preBase = (base_, protect = false) => {
  return protect
    ? config.APP_PROTECTED_URL + base_
    : config.APP_BASE_URL + base_;
};

const routes = {
  public: {
    path: preBase("/"),
    component: MainLayout,
    routes: [
      {
        path: preBase("/"),
        component: Home,
      },
      {
        path: preBase("/about"),
        component: About,
      },
      {
        path: preBase("/faq"),
        component: FAQ,
      },
      {
        path: preBase("/contact"),
        component: Contact,
      },
      {
        path: "*",
        component: NotFound404,
      },
    ],
  },
  private: {
    path: preBase("", true),
    component: DashboardLayout,
    routes: [
      {
        path: preBase("", true),
        component: getProtectedLazyPage("private/Dashboard"),
      },
      {
        path: preBase("/form-submit", true),
        component: getProtectedLazyPage("private/FormSubmit"),
      },
      {
        path: preBase("/inbox", true),
        component: getProtectedLazyPage("private/Inbox"),
      },
      {
        path: preBase("/profile", true),
        component: getProtectedLazyPage("private/Profile"),
      },
      {
        path: preBase("/settings", true),
        component: getProtectedLazyPage("private/Settings"),
      },
      {
        path: preBase("/support", true),
        component: getProtectedLazyPage("private/Support"),
      },
      {
        path: preBase("/admin-support", true),
        component: getProtectedLazyPage("private/city/user-admin/AdminSupport"),
        role: "user-admin",
      },
      {
        path: preBase("/my-abatements", true),
        component: getProtectedLazyPage(
          "private/business/MyAbatementsOverview"
        ),
        role: "business",
      },
      {
        path: preBase("/my-documents", true),
        component: getProtectedLazyPage("private/business/MyDocuments"),
        role: "business",
      },
      {
        path: preBase("/abatements", true),
        component: getProtectedLazyPage(
          "private/city/city-admin/AbatementsOverview"
        ),
        role: "city-admin",
      },
      {
        path: preBase("/abatements/:id", true),
        component: getProtectedLazyPage("private/city/city-admin/Abatements"),
        role: "city-admin",
      },
      {
        path: preBase("/analysis", true),
        component: getProtectedLazyPage("private/city/city-official/Analysis"),
        role: "city-official",
      },
      {
        path: preBase("/documents", true),
        // component: getProtectedLazyPage("private/city/city-admin/Documents"),
        component: Documents,
        role: "city-admin",
      },
      {
        path: preBase("/*", true),
        component: NotFound404,
      },
    ],
  },
};

export default function Router() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {isAuthenticated && (
            <RouteWithSubRoutes
              key="private"
              exact={false}
              {...routes.private}
            />
          )}
          {!isAuthenticated && (
            <Route path="/app" render={() => loginWithRedirect()} />
          )}
          <RouteWithSubRoutes key="public" exact={false} {...routes.public} />
        </Switch>
      </Suspense>
    </>
  );
}
