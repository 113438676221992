import React from "react";
import {
  styled,
  List,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography,
  Box,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { useAuth0 } from "@auth0/auth0-react";
import { authRoutes } from "../routing";
import LogoIcon from "./Logo";

const SidebarRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const SidebarWrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  p: 2,
});

const StyledIcon = styled(LogoIcon)(() => ({
  maxWidth: 55,
}));

const SidebarContainer = styled("div")({
  width: 224,
  top: 64,
  height: "calc(100% - 64px)",
});

export default function DashboardSidebar({ sidebarOpen, closeSidebar }) {
  const { user } = useAuth0();
  const authedRoutes = authRoutes(user);

  const content = (
    <SidebarRoot>
      <SidebarWrapper>
        <Box sx={{ flexGrow: 1 }} />
        <Link href="/">
          <StyledIcon />
        </Link>
        <Typography variant="h5" color="inherit" noWrap>
          E-Abatements
        </Typography>
      </SidebarWrapper>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {authedRoutes.map((r) => (
            <NavItem key={r} route={r} closeSidebar={closeSidebar} />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </SidebarRoot>
  );
  return (
    <>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          PaperProps={{
            sx: {
              width: 224,
            },
          }}
        >
          <SidebarContainer>{content}</SidebarContainer>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          open={sidebarOpen}
          onClose={(ev, reason) => closeSidebar()}
          PaperProps={{
            sx: {
              width: 224,
            },
          }}
        >
          <SidebarContainer>{content}</SidebarContainer>
        </Drawer>
      </Hidden>
    </>
  );
}
