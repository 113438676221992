import React from 'react';
import PropTypes from 'prop-types';
import { pdfjs } from 'react-pdf';
import IframeResizer from 'iframe-resizer-react'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFViewer(props) {

  return (
    <IframeResizer
      log
      src={props.pdfUrl} 
      style={{ minHeight: '600px', minWidth: '100%'}}
    />
  );
}

PDFViewer.propTypes = {
    pdfUrl: PropTypes.string,
}