import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./App.styles";

import {
  styled,
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Box,
  Hidden,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogoIcon from "./Logo";

import { useAuth0 } from "@auth0/auth0-react";

const NavbarLayoutWrapper = styled("div")(({ theme }) => ({
  // [theme.breakpoints.up("md")]: {
  //   paddingLeft: 224,
  // },
  elevation: 0,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.dark,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  letterSpacing: 0,
  py: 1.25,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

const StyledIcon = styled(LogoIcon)(() => ({
  maxWidth: 55,
}));

export default function MainNavbar({ toggleSidebar }) {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const classes = useStyles();

  return (
    <AppBar elevation={0}>
      <NavbarLayoutWrapper>
        <StyledToolbar>
          <Link to="/">
            <StyledIcon />
          </Link>
          <Hidden mdUp>
            <Grid item xs={1}>
              <IconButton color="inherit" onClick={toggleSidebar}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Box sx={{ flexGrow: 1 }} />
          <Hidden smDown>
            <Grid item xs={4}>
              <div className={classes.topRightNavbar} flexdirection="row">
                <StyledLink to="/about">About</StyledLink>
                <StyledLink to="/contact">Contact</StyledLink>
                <StyledLink to="/faq">FAQ</StyledLink>
                {isAuthenticated && (
                  <StyledLink
                    to="/"
                    onClick={() =>
                      logout({
                        returnTo: window.location.origin,
                      })
                    }
                  >
                    Logout
                  </StyledLink>
                )}
                {!isAuthenticated && (
                  <StyledLink to="/" onClick={() => loginWithRedirect()}>
                    Login
                  </StyledLink>
                )}
              </div>
            </Grid>
          </Hidden>
        </StyledToolbar>
      </NavbarLayoutWrapper>
    </AppBar>
  );
}
