import React from "react";
import {
  styled,
  List,
  Divider,
  Drawer,
  Typography,
  Hidden,
  Link,
  Button,
  ListItem,
  Box,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import NavItem from "./NavItem";
import { useAuth0 } from "@auth0/auth0-react";
import LogoIcon from "./Logo";

const SidebarRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const SidebarWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const SidebarContainer = styled("div")({
  width: 224,
  top: 64,
  height: "calc(100% - 64px)",
});

const StyledIcon = styled(LogoIcon)(() => ({
  maxWidth: 55,
}));

const ButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  alignContent: "center",
});

export default function DashboardSidebar({ sidebarOpen, closeSidebar }) {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const content = (
    <SidebarRoot>
      <SidebarWrapper>
        <Box sx={{ flexGrow: 1 }} />
        <Link href="/">
          <StyledIcon />
        </Link>
        <Typography variant="h5" color="inherit" noWrap>
          E-Abatements
        </Typography>
      </SidebarWrapper>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {["/about", "/contact", "/faq"].map((r) => (
            <NavItem key={r} route={r} closeSidebar={closeSidebar} />
          ))}
          {isAuthenticated && (
            <ListItem>
              <Button
                component={NavLink}
                sx={{
                  color: "text.secondary",
                  fontWeight: "medium",
                  justifyContent: "flex-start",
                  letterSpacing: 0,
                  py: 1.25,
                  width: "100%",
                  "& svg": {
                    mr: 1,
                  },
                }}
                to="/"
                onClick={() =>
                  logout({
                    returnTo: window.location.origin,
                  })
                }
              >
                <ButtonContainer>
                  <span>Logout</span>
                </ButtonContainer>
              </Button>
            </ListItem>
          )}
          {!isAuthenticated && (
            <ListItem>
              <Button
                component={NavLink}
                sx={{
                  color: "text.secondary",
                  fontWeight: "medium",
                  justifyContent: "flex-start",
                  letterSpacing: 0,
                  py: 1.25,
                  width: "100%",
                  "& svg": {
                    mr: 1,
                  },
                }}
                to="/"
                onClick={() => loginWithRedirect()}
              >
                <ButtonContainer>
                  <span>Login</span>
                </ButtonContainer>
              </Button>
            </ListItem>
          )}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </SidebarRoot>
  );
  return (
    <>
      {/* <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          PaperProps={{
            sx: {
              width: 224,
            },
          }}
        >
          <SidebarContainer>{content}</SidebarContainer>
        </Drawer>
      </Hidden> */}
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarOpen}
          onClose={(ev, reason) => closeSidebar()}
          PaperProps={{
            sx: {
              width: 224,
            },
          }}
        >
          <SidebarContainer>{content}</SidebarContainer>
        </Drawer>
      </Hidden>
    </>
  );
}
