export const colors = {
  lightBlue: "#00AFEF",
  darkBlue: "#0D71BB",
  lightGreen: "#6BB34F",
  darkGreen: "#00a857",
  offwhite: "#F8FAF3",
  white: "#FFFFFF",
  black: "#000000",
  red: "#FF0000",
};
