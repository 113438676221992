import React from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { styled, Button, ListItem } from "@material-ui/core";
import getIcon from "../assets/icons";
import { toTitleCase } from "../utils/strings";

const getNameFromPath = (path) => {
  const name = toTitleCase(path.split("/").at(-1));
  if (name === "App") {
    return "Home";
  }
  return name;
};

const ButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  alignContent: "center",
});

const IconContainer = styled("div")({
  marginRight: 15,
});

export default function NavItem({ route, closeSidebar, ...rest }) {
  const location = useLocation();
  const active = route
    ? !!matchPath(
        {
          path: route,
          end: false,
        },
        location.pathname
      )
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
      {...rest}
    >
      <Button
        component={NavLink}
        sx={{
          color: "text.secondary",
          fontWeight: "medium",
          justifyContent: "flex-start",
          letterSpacing: 0,
          py: 1.25,
          width: "100%",
          ...(active && {
            color: "primary.main",
          }),
          "& svg": {
            mr: 1,
          },
        }}
        to={route}
        onClick={() => closeSidebar()}
      >
        <ButtonContainer>
          <IconContainer>{getIcon(route)}</IconContainer>
          <span>{getNameFromPath(route)}</span>
        </ButtonContainer>
      </Button>
    </ListItem>
  );
}
