import React from "react";
import {
  styled,
  AppBar,
  Container,
  Toolbar,
  Typography,
} from "@material-ui/core";

const FooterContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
  zIndex: "1",
}));

export default function Footer({ sidebarOpen }) {
  return (
    <>
      <FooterContainer>
        <AppBar>
          <Container maxWidth="md">
            <Toolbar>
              <Typography>Copyright © 2021 eAbatements</Typography>
            </Toolbar>
          </Container>
        </AppBar>
      </FooterContainer>
    </>
  );
}
