import React from "react"
import { Link } from "react-router-dom"
import AuthButton from "../components/AuthButton"

export default function HomePage() {
  return (
    <>
      <h1>Home Page</h1>
      <AuthButton />
      <br />
      <Link to="/app">Dashboard</Link>
    </>
  )
}
