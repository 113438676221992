import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 100;
const footerHeight = 64;
const appBarHeight = 64;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    appBar: {
      backgroundColor: "#535353",
      minHeight: appBarHeight,
      zIndex: 30,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    headline: {
      color: theme.palette.primary.main,
      marginLeft: drawerWidth,
    },
    menuButton: {
      marginRight: 25,
    },
    topRightNavbar: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      alignItems: "center",
    },
    avatar: {
      cursor: "pointer",
      width: 64,
      height: 64,
    },
    navButton: {
      color: "text.secondary",
      fontWeight: "medium",
      justifyContent: "flex-start",
      letterSpacing: 0,
      py: 1.25,
      textTransform: "none",
      width: "100%",
      "& svg": {
        mr: 1,
      },
    },
    activeNav: {
      color: theme.palette.secondary.main,
    },
    hide: {
      display: "none",
    },
    drawer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    link: {
      color: "#FFFFFF",
      textDecoration: "none",
    },
    rightContentDiv: {
      display: "flex",
      flexFlow: "column nowrap",
      alignContent: "space-between",
      height: "100%",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerHide: {
      transition: theme.transitions.create("visibility", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      visibility: "hidden",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    },
    sidebarIcon: {
      color: theme.palette.primary.dark,
    },
    sidebarLink: {
      color: theme.palette.primary.dark,
      textDecoration: "none",
    },
    toolbar: {
      minHeight: "64px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      marginTop: appBarHeight + 10,
      bottom: footerHeight,
      marginLeft: drawerWidth,
      paddingLeft: 25,
    },
    footer: {
      position: "fixed",
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: footerHeight,
      bottom: 0,
      margin: 0,
      padding: 0,
    },
    footerShift: {
      paddingLeft: drawerWidth,
      transition: theme.transitions.create(["width", "margin", "padding"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  };
});

export default useStyles;
